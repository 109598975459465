import { Col, Row, Table } from 'antd';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CloseCircleOutlined } from '@ant-design/icons'

import lang from '../../../language/language'
import { setItemList } from '../slice'
import { formatCurrency, formatPhone } from '../../Common/components/FormatData';

const CheckChoosed = () => {
    const itemList = useSelector(state => state.checkin.itemList)
    const customerInfo = useSelector(state => state.checkin.customerInfo)
    const setting = useSelector(state => state.common.setting)

    const dispatch = useDispatch()

    const onDeleteChoose = (id) => {
        dispatch(setItemList(itemList.filter(x => x.service_id !== id)))
    }

    const CHECK_CHOOSED = [
        {
            title: lang.choosed,
            width: '45%',
            dataIndex: 'service_name',
            key: 'service_name'
        },
        {
            title: lang.price_service,
            width: '20%',
            dataIndex: 'service_price',
            key: 'service_price',
            render: (text, record) => {
                return <div><span className={`${record.service_price_discount && 'line-through limiter'}`}>{formatCurrency(record.service_price, setting)}</span> {record.service_price_discount && <span>{formatCurrency(record.service_price_discount, setting)}</span>}</div>
            }
        },
        {
            title: lang.staff_choosed,
            width: '30%',
            dataIndex: 'staff_fullname',
            key: 'staff_fullname'
        },
        {
            title: ' ',
            width: '5%',
            key: 'action',
            render: (_, record) => (
                <CloseCircleOutlined onClick={() => onDeleteChoose(record.service_id)} />
            )
        }
    ]

    return (
        <Row gutter={24} className="pb-10">
            <Col md={8} className='font-weight-bold'>{lang.customer}: {customerInfo.customer_fullname}</Col>
            <Col md={10} className='font-weight-bold'>{lang.customer_phone_number}: {formatPhone(customerInfo.customer_phone_number, setting)}</Col>
            <Col md={6} className='font-weight-bold'>{lang.loyalty_point}: {customerInfo.customer_reward_point}</Col>
            <Col md={24}>
                <Table
                    className='mt-15'
                    columns={CHECK_CHOOSED}
                    dataSource={itemList}
                    pagination={false}
                    scroll={{
                        x: '100%',
                        y: 250
                    }}
                />
            </Col>
        </Row>
    )
}

export default CheckChoosed;
