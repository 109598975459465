import vi from './vi';
import en from './en';

const getLang = localStorage.getItem('language')
const langData = (getLang && (getLang === 'en' || getLang === 'en')) ? getLang : 'en'
let lang = {};

if (langData === 'vi') {
  lang = vi;
} else if (langData === 'en') {
  lang = en;
}

export default lang;
