import { createSlice } from '@reduxjs/toolkit';
import * as LoginPhoneThunk from './thunk';

export const LoginPhoneSlice = createSlice({
  name: 'loginPhone',
  initialState: {
    loginPhoneNew: ''
  },
  reducers: {
    setLoginPhoneNew: (state, action) => {
      state.loginPhoneNew = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [LoginPhoneThunk.LoginPhoneList.fulfilled]: (state, action) => {
      state.loginPhoneList = action.payload;
    }
  }
});

export const {
  setLoginPhoneNew
} = LoginPhoneSlice.actions;

export default LoginPhoneSlice.reducer;
