import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateTitle } from '../../modules/Common/slice';
import lang from '../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../constants/config';

const NotFound = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateTitle(''));
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.not_found}</title>
      </Helmet>
      <div name="content" className="text-center pt-5" style={{ minHeight: '91.5vh' }}>
        <h1 name="m-0 ">404</h1>
        <h6>{lang.not_found_page}</h6>
      </div>
    </div>
  );
};

export default NotFound;
