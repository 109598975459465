import CallApi from '../../utils/CallAPI';
import {
  STAFF,
  CREATE_INVOICE,
  CHECKIN_SETTING
} from './constants/endpoint';

export const getStaff = (data) => {
  return CallApi(STAFF, 'GET', data)
};

export const createInvoice = (data) => {
  return CallApi(CREATE_INVOICE, 'POST', data)
};

export const checkinSetting = (data) => {
  return CallApi(CHECKIN_SETTING, 'GET', data)
};
