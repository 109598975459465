const vi = {
  user_name: 'Tên đăng nhập',
  not_format: 'Không đúng định dạng',
  login_main_top: 'Chào mừng bạn đến với Hexcore!',
  login_main_bottom: 'Hãy đăng nhập để trải nghiệm dịch vụ tốt nhất',
  user_code_required: 'Mã người dùng là bắt buộc',
  user_name_required: 'Tên đăng nhập là bắt buộc',
  user_password_required: 'Mật khẩu là bắt buộc',
  user_fullname_required: 'Tên người dùng là bắt buộc',
  save_and_close: 'Lưu và đóng',
  save: 'Lưu',
  password: 'Mật khẩu',
  login: 'Đăng nhập',
  forgot_password: 'Quên mật khẩu',
  login_not_correct: 'Thông tin đăng nhập không chính xác',
  not_found_page: 'Không tìm thấy trang',
  not_found: 'Không tìm thấy trang',
  login_phone: 'Nhập số điện thoại',
  value_is_required: 'Giá trị này là bắt buộc',
  please_input_phone_number: 'Please enter your cell phone number',
  info_will_not_shared: 'Your information will not be shared',
  input_phone_number: 'Nhập số điện thoại...',
  register: 'Đăng ký',
  go_back: 'Về trang chủ',
  customer_phone_number: 'Số điện thoại',
  customer_fullname: 'Họ và tên',
  customer_birthday: 'Ngày sinh',
  customer: 'Khách hàng',
  checkin: 'Checkin',
  staff_title: 'Nhân viên',
  service_title: 'Dịch vụ',
  choose_by_staff: 'Chọn theo nhân viên',
  choose_by_service: 'Chọn theo dịch vụ',
  find_staff: 'Tìm mã, tên nhân viên...',
  find_service: 'Tìm mã, tên dịch vụ...',
  input_wrong: 'Dữ liệu nhập vào không hợp lệ',
  pls_check: 'Vui lòng check vào ô Điều khoản',
  check_choosed: 'Xác nhận',
  choosed: 'Dịch vụ',
  next_step: 'Tiếp tục',
  price_service: 'Giá',
  staff_choosed: 'Nhân viên',
  pls_choose_service: 'Hãy chọn dịch vụ trước khi qua bước tiếp theo',
  policy: 'By checking the box and clicking NEXT, you give HexCheckin, as well as HexCore, express written consent to contact you at the number entered for reminder or promotional purposes. Consent is not required to make a purchase. You also  agree to the ',
  terms_and_conditions_title: 'Terms and Conditions',
  privacy_policy_title: 'Privacy Policy',
  salon_policies_title: 'Salon Policies',
  terms_and_conditions: 'This is Terms and Conditions',
  privacy_policy: 'This is Privacy Policy',
  salon_policies: 'This is Salon Policies',
  user_not_found: 'Tài khoản không tồn tại',
  exist_customer_code: 'Mã khách hàng đã tồn tại',
  exist_customer_email: 'Email đã tồn tại',
  exist_customer_phone_number: 'Số điện thoại đã tồn tại',
  welcome: 'Welcome',
  greeting_checkin: 'Please choose your services or your preferred staff, but if you walk in, you might have to wait for your technician or we woll arrange someone else for you.',
  loyalty_point: ' Điểm tích lũy',
  price_discount: 'Chiết khấu',
  invoice_success: 'Đặt lịch thành công',
  invoice_error: 'Lỗi đặt lịch',
  login_exist: 'Tài khoản đã được đăng nhập từ máy khác',
  invoice_code: 'Mã hoá đơn',
  service_ticket: 'PHIẾU DỊCH VỤ',
  invoice_item_name: 'Dịch vụ / sản phẩm',
  print_ticket_note: 'Vui lòng mang phiếu này đến quầy thanh toán sau khi sử dụng dịch vụ',
  create_date: 'Ngày tạo',
  checkin_success: 'Check-in thành công',
  staff_is_busy: 'Nhân viên này hiện tại đang bận, bạn có thể phải chờ đến lượt',
  pls_choose_staff: 'Hãy chọn nhân viên trước khi qua bước tiếp theo',
  phone_number: 'Điện thoại'
};

export default vi;
