import React from 'react'
import { Checkbox, Col, Collapse, Input, List, Row, Space } from 'antd'
import { DollarCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux';

import lang from '../../../language/language'
import '../styles/style.css'
import { setQueryServiceList, setItemList, setQueryStaffList } from '../slice'
import { formatCurrency } from '../../Common/components/FormatData';

const { Search } = Input;
const { Panel } = Collapse;

const ShowServiceList = () => {
    const serviceList = useSelector(state => state.checkin.serviceList)
    const queryServiceList = useSelector(state => state.checkin.queryServiceList)
    const staffChoosed = useSelector(state => state.checkin.staffChoosed)
    const itemList = useSelector(state => state.checkin.itemList)
    const queryStaffList = useSelector(state => state.checkin.queryStaffList)
    const currentTabs = useSelector(state => state.checkin.currentTab)
    const setting = useSelector(state => state.common.setting)

    const dispatch = useDispatch()

    const reloadStaff = (id) => {
        if (currentTabs === '2') {
            const reloadStaff = [...queryStaffList.listServiceId]
            reloadStaff.push({
                serviceId: id
            })

            dispatch(setQueryStaffList({ ...queryStaffList, listServiceId: reloadStaff }))
        }
    }

    const onChoose = (data) => {
        // [{service_code: '',service_name:'',service_price:'', service_price_discount:'', staff_id:''}]
        let listItem = [...itemList]
        if (listItem.some(x => x.service_id === data.service_id)) { // kiểm tra trạng thái chọn của service
            listItem = listItem.filter(x => x.service_id !== data.service_id)
        } else {
            if (currentTabs === '1') {
                listItem.push({
                    service_id: data.service_id,
                    service_code: data.service_code,
                    service_name: data.label,
                    service_price: data.service_price,
                    service_price_discount: data.service_price_discount,
                    ...staffChoosed
                })
            } else {
                listItem.push({
                    service_id: data.service_id,
                    service_code: data.service_code,
                    service_name: data.label,
                    service_price: data.service_price,
                    service_price_discount: data.service_price_discount
                })
            }
        }

        reloadStaff(data.service_id)
        dispatch(setItemList(listItem))
    }

    const onSearch = (value) => {
        dispatch(setQueryServiceList({ ...queryServiceList, search: value }))
    }

    return (
        <Row gutter={24}>
            <Col md={24}>
                <Search
                    size="large"
                    className='i-search mb-15'
                    placeholder={lang.find_service}
                    enterButton
                    onSearch={onSearch}
                />
                <div className='container-service-list'>
                    <Collapse
                        bordered={false}
                        accordion
                    >
                        {serviceList.map((item) => {
                            return (<Panel header={<span className="f-16">{item.label}</span>} key={item.value}>
                                <List
                                    itemLayout="horizontal"
                                    className="ml-5 list-scroll-service"
                                    dataSource={item.children}
                                    renderItem={x => {
                                        return (
                                            <List.Item
                                                key={x.service_id}
                                            >
                                                <Space direction="vertical">
                                                    <Checkbox
                                                        onChange={() => onChoose(x)}
                                                    >{x.label}</Checkbox>
                                                </Space>
                                                <div className="f-13">
                                                    <DollarCircleOutlined /> <span className={`${x.service_price_discount && 'line-through limiter'}`}>{formatCurrency(x.service_price, setting)}</span> {x.service_price_discount && <span>{formatCurrency(x.service_price_discount, setting)}</span>} - <ClockCircleOutlined /> {x.service_duration} {lang.minute}</div>
                                            </List.Item>
                                        )
                                    }}
                                />
                            </Panel>
                            )
                        })}
                    </Collapse>
                </div>
            </Col>
        </Row>
    )
}

export default ShowServiceList;
