
import React from 'react';
import { Spin } from 'antd';

const Spinner = ({ loading }) => (
  loading &&
  <div className="loading">
    <div className="loading-container">
      <Spin size="large" />
    </div>
  </div>
);

export default Spinner;
