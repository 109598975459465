/* eslint-disable camelcase */
import { React, useEffect, useState } from 'react'
import { Col, Row, Button, Carousel, Card, Checkbox, message, Popover } from 'antd'
import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import { KEYBOARD } from '../../../constants/common'
import '../styles/style.css'
import imglogo from '../../../assets/images/logo-hexcore.png'
import lang from '../../../language/language'
import { ROUTE_REGISTER } from '../../Register/constants/routes'
import { ROUTE_CHECKIN } from '../../Checkin/constants/routes'
import { formatPhone } from '../../../modules/Common/components/FormatData'
import { setLoginPhoneNew } from '../slice'
import { getSlider, checkLoginPhone } from '../service'
import { setLoading, setSetting, setUserInfo } from '../../Common/slice'
import { useHistory } from 'react-router-dom'
import { setCurrentStep, setCurrentTab, setCustomerInfo, setItemList, setQueryServiceList, setQueryStaffList, setStaffChoosed } from '../../Checkin/slice'
import { getSetting, getUserInfo } from '../../Common/service'
import parseHTML from 'html-react-parser'

const LoginPhone = (props) => {
  const [phoneNum, setPhoneNum] = useState('')
  const [chkPolicyLogin, setChkPolicyLogin] = useState(false)
  const [silderList, setSilderList] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()
  const infoUser = JSON.parse(localStorage.getItem('infoUser'));
  const { user_id } = infoUser && infoUser;
  const checkinSetting = useSelector(state => state.checkin.checkinSetting)

  useEffect(() => {
    getListSlider()
    dispatch(setLoginPhoneNew(''))
    dispatch(setCustomerInfo({}))
    getAllUserInfo(user_id)
    getAllSetting()

    dispatch(setCurrentTab('1'))
    dispatch(setCurrentStep(0))
    dispatch(setStaffChoosed({}))
    dispatch(setItemList([]))

    const queryStaffListDefault = {
      listServiceId: [],
      listBranchId: [],
      search: ''
    }
    dispatch(setQueryStaffList(queryStaffListDefault))

    const queryServiceList = {
      staff_id: null,
      search: ''
    }
    dispatch(setQueryServiceList(queryServiceList))
  }, []);

  const getAllSetting = () => {
    dispatch(setLoading(true))
    const reqData = {}
    getSetting(reqData).then(res => {
      if (res.data) {
        dispatch(setSetting(res.data))
      }
    }).finally(x => {
      dispatch(setLoading(false))
    }
    )
  }

  const getAllUserInfo = (id) => {
    dispatch(setLoading(true))
    const reqData = {
      user_id: id
    }
    getUserInfo(reqData).then(res => {
      if (res.data) {
        dispatch(setUserInfo(res.data))
      }
    }).finally(x => {
      dispatch(setLoading(false))
    }
    )
  }

  const getListSlider = () => {
    dispatch(setLoading(true))
    const reqData = {}
    getSlider(reqData).then(res => {
      if (res.data) {
        setSilderList(res.data)
      }
    }).finally(x => {
      dispatch(setLoading(false))
    }
    )
  }

  const loginPhone = () => {
    dispatch(setLoading(true))
    const reqData = {
      phoneNumber: phoneNum
    }
    checkLoginPhone(reqData).then(res => {
      if (res.data) {
        if (res.data.customer_id) { // kiểm tra hệ thống đã có thông tin user này chưa
          dispatch(setCustomerInfo(res.data))
          history.push(ROUTE_CHECKIN)
        } else {
          dispatch(setLoginPhoneNew(phoneNum))
          history.push(ROUTE_REGISTER)
        }
      }
    }).finally(x => {
      dispatch(setLoading(false))
    }
    )
  }

  const inputPhone = (item) => {
    if (item < 10) { // kiểm tra độ dài sdt đã nhập
      if (phoneNum.length < 10) setPhoneNum(phoneNum + item)
      else message.error(lang.input_wrong);
    } else if (item === 'del') setPhoneNum('') // user bấm nút xóa
    else if (item === 'done' && phoneNum.length === 10) { // user bấm nút done
      if (chkPolicyLogin) { // kiểm tra user đã check policy chưa
        loginPhone()
      } else message.error(lang.pls_check);
    } else {
      message.error(lang.input_wrong);
    }
  }

  const onChangeChkbox = () => {
    setChkPolicyLogin(!chkPolicyLogin)
  }

  return (
    <Card className='container-screen'>
      <Row className="aign-center">
        <Col md={12} className="pr-15">
          <Carousel dotPosition='left' effect="scrollx" autoplay slidesToShow={2}>
            {silderList.map((item, index) => {
              return <div className="img-ads-container" key={index}>
                <img className='img-ads' src={item.checkin_app_slider_image} />
              </div>
            })
            }
          </Carousel>
          <Row gutter={24}>
            <Col md={2} className="pr-0"><Checkbox className='chk-policy-login' checked={chkPolicyLogin} onChange={onChangeChkbox}></Checkbox></Col>
            <Col md={22} className="pl-0">
              {lang.policy}
              <Popover
                overlayStyle={{
                  width: '30vw'
                }}
                content={lang.terms_and_conditions}
                title={lang.terms_and_conditions_title}
                trigger="click"
                placement="topLeft"
              >
                <b className='cursor-pointer' >{lang.terms_and_conditions_title}</b>
              </Popover>
              <span>, </span>
              <Popover
                overlayStyle={{
                  width: '30vw'
                }}
                placement="topLeft"
                content={lang.privacy_policy}
                title={lang.privacy_policy_title}
                trigger="click"
              >
                <b className='cursor-pointer' >{lang.privacy_policy_title}</b><span> and </span>
              </Popover>
              <Popover
                overlayStyle={{
                  width: '30vw'
                }}
                placement="topLeft"
                content={<div className='container-policy'>
                  {parseHTML(checkinSetting.checkin_setting_policies || '')}
                </div>}
                title={lang.salon_policies_title}
                trigger="click"
              >
                <b className='cursor-pointer' >{lang.salon_policies_title}</b><span>.</span>
              </Popover>
            </Col>
          </Row>
        </Col>
        <Col md={12} className="pl-15">
          <Card className="container-card-login-phone pt-0">
            <Row gutter={12}>
              <Col md={24} className="text-center pb-5">
                <img className='img-logo' src={imglogo} />
              </Col>
              <Col md={24} className="text-center pb-5">
                <h3 className="mb-0">{lang.please_input_phone_number}</h3>
                <h3 className="mb-0">{lang.info_will_not_shared}</h3>
              </Col>
              <Col md={24} >
                <div className='i-num text-center'>{formatPhone(phoneNum)}</div>
              </Col>
            </Row>
            <Row gutter={12} className="pt-5 pl-15 pr-15">
              {KEYBOARD.map((item, index) => {
                return (
                  <Col className='text-center' md={8} key={index}>
                    <Button className='btn-num' onClick={() => inputPhone(item)} type="primary" shape="circle" block>
                      {item === 'del' ? <ArrowLeftOutlined /> : item === 'done' ? <CheckOutlined /> : item}
                    </Button>
                  </Col>
                )
              })}
            </Row>
          </Card>
        </Col>
      </Row>
    </Card>
  )
};

export default LoginPhone;
