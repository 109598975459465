import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Card, Col, message, Row, Space, Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import lang from '../../../language/language'
import '../styles/style.css'
import { ROUTE_LOGIN_PHONE } from '../../LoginPhone/constants/routes'
import TabsStaff from './TabsStaff'
import TabsService from './TabsService'
import { setCurrentStep, setCurrentTab, setItemList, setQueryServiceList, setQueryStaffList, setStaffChoosed } from '../slice'
import * as checkinThunk from '../thunk'
import { setLoading } from '../../Common/slice'
import { RandomCode } from '../../Common/components/RandomCode'
import { createInvoice } from '../service'
import PrintTicket from './PrintTicket'
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'

const Checkin = () => {
  // currentTabs: check khách hàng đang dừng ở tabs nào
  const currentTabs = useSelector(state => state.checkin.currentTab)
  const currentStep = useSelector(state => state.checkin.currentStep)
  // choosedService: check xem KH đã chọn dịch vụ nào chưa
  const itemList = useSelector(state => state.checkin.itemList)
  const customerInfo = useSelector(state => state.checkin.customerInfo)
  const queryStaffList = useSelector(state => state.checkin.queryStaffList)
  const queryServiceList = useSelector(state => state.checkin.queryServiceList)
  const checkinSetting = useSelector(state => state.checkin.checkinSetting)
  const userInfo = useSelector(state => state.common.userInfo)
  const [dataPrint, setDataPrint] = useState({})
  const dispatch = useDispatch()
  const history = useHistory()
  const staffChoosed = useSelector(state => state.checkin.staffChoosed)

  const printTicketRef = useRef();

  const handlePrintTicket = useReactToPrint({
    content: () => printTicketRef.current
  });

  useEffect(() => {
    if (Object.keys(customerInfo).length === 0) {
      history.push(ROUTE_LOGIN_PHONE)
    }
  }, [])

  useEffect(() => {
    if (currentTabs === '2') {
      dispatch(setQueryServiceList({ ...queryServiceList, staff_id: null }))
      dispatch(setStaffChoosed({}))
    }
  }, [currentTabs])

  useEffect(() => {
    if (userInfo.branch_id) {
      fetchDataStaffList({ ...queryStaffList, listBranchId: [userInfo.branch_id], date: moment().format('YYYY-MM-DD') })
    }
  }, [queryStaffList, userInfo])

  useEffect(() => {
    fetchDataServiceList(queryServiceList)
  }, [queryServiceList])

  const createNewInvoice = () => {
    if (itemList.length === 0) {
      message.error(lang.pls_choose_service);
    } else if (!staffChoosed.staff_id) {
      message.error(lang.pls_choose_staff);
    } else {
      dispatch(setLoading(true))
      const reqData = {
        invoice_code: RandomCode(),
        create_user: userInfo.user_id,
        branch_id: 1,
        item_list: itemList,
        customer_id: customerInfo.customer_id
      }
      createInvoice(reqData).then(res => {
        if (res.data) {
          message.success(lang.checkin_success);
          setDataPrint(res.data)
          setTimeout(() => {
            handlePrintTicket()
            history.push(ROUTE_LOGIN_PHONE)
          }, 500)
        } else message.error(lang.invoice_error);
      }).finally(x => {
        dispatch(setLoading(false))
      }
      )
    }
  }

  const onClick = () => {
    // check xem KH đang ở tab nào
    // nếu kh đang ở tab chọn nv thì step 1( chọn nhân viên) sẽ không yêu cầu chọn.
    // logic yêu cầu chọn sẽ đặt ở step 2
    if (currentTabs === '1') {
      if (currentStep === 0) {
        if (staffChoosed.staff_id) {
          dispatch(
            setCurrentStep(currentStep + 1)
          )
        } else {
          message.error(lang.pls_choose_staff);
        }
      } else if (currentStep === 1) {
        if (itemList.length > 0) {
          dispatch(
            setCurrentStep(currentStep + 1)
          )
        } else {
          message.error(lang.pls_choose_service);
        }
      } else if (currentStep === 2) {
        createNewInvoice()
      }
    } else {
      // nếu kh đang ở tab chọn serv thì step 1 sẽ yêu cầu chọn trước khi qua step kế
      if (currentStep === 0) {
        if (itemList.length > 0) {
          dispatch(
            setCurrentStep(currentStep + 1)
          )
        } else {
          message.error(lang.pls_choose_service);
        }
      } else if (currentStep < 2) {
        if (staffChoosed.staff_id) {
          dispatch(
            setCurrentStep(currentStep + 1)
          )
        } else {
          message.error(lang.pls_choose_staff);
        }
      } else if (currentStep === 2) {
        createNewInvoice()
      }
    }
  }

  const fetchDataStaffList = (data = {}) => {
    dispatch(checkinThunk.staffList(data));
  };

  const fetchDataServiceList = (data = {}) => {
    dispatch(checkinThunk.serviceList(data));
  };

  const onChangeTabs = (value) => {
    dispatch(setCurrentTab(value))

    dispatch(setCurrentStep(0))
    dispatch(setStaffChoosed({}))
    dispatch(setItemList([]))

    const queryStaffListDefault = {
      listServiceId: [],
      listBranchId: [],
      search: ''
    }
    dispatch(setQueryStaffList(queryStaffListDefault))

    const queryServiceList = {
      staff_id: null,
      search: ''
    }
    dispatch(setQueryServiceList(queryServiceList))
  }

  return (
    <Card className='container-screen'>
      <Row gutter={24}>
        <Col md={24}>
          <div className='title-all checkin-title'>
            {checkinSetting.checkin_setting_welcome ? checkinSetting.checkin_setting_welcome.replace('[customer_fullname]', customerInfo.customer_fullname) : lang.welcome}
            <div className='greating_checkin'>{checkinSetting.checkin_setting_description || lang.greeting_checkin}</div>
          </div>
        </Col>
        <Col md={24}>
          <Tabs defaultActiveKey="1" onChange={onChangeTabs}>
            <Tabs.TabPane tab={lang.choose_by_staff} key="1">
              <TabsStaff />
            </Tabs.TabPane>
            <Tabs.TabPane tab={lang.choose_by_service} key="2">
              <TabsService />
            </Tabs.TabPane>
          </Tabs>
        </Col>
        <Col md={24} className='text-center pt-15'>
          <Space size={'large'}>
            <Link to={ROUTE_LOGIN_PHONE}><Button type="primary" size='large'>
              {lang.go_back}
            </Button></Link>
            <Button type="primary" size='large' onClick={onClick}>
              {currentStep === 0 ? lang.next_step : currentStep === 1 ? lang.next_step : lang.checkin}
            </Button>
          </Space>
        </Col>
      </Row>
      <PrintTicket printRef={printTicketRef} data={dataPrint} dataItem={dataPrint.invoiceItemList} />
    </Card>
  )
};
export default Checkin;
