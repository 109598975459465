import React, { useState, useEffect } from 'react'
import { Steps } from 'antd'
import { useSelector } from 'react-redux'

import lang from '../../../language/language'
import '../styles/style.css'
import ShowStaffList from '../components/ShowStaffList'
import ShowServicesList from '../components/ShowServicesList'
import CheckChoosed from '../components/CheckChoosed'

const TabsStaff = () => {
    const stepContent = [
        {
            title: lang.staff_title,
            content: <ShowStaffList />
        },
        {
            title: lang.service_title,
            content: <ShowServicesList />
        },
        {
            title: lang.check_choosed,
            content: <CheckChoosed />
        }
    ]

    const Step = Steps.Step
    const [current, setCurrent] = useState(0)

    const currentStep = useSelector(state => state.checkin.currentStep)

    const onChange = (value) => {
        setCurrent(value);
    }

    useEffect(() => {
        setCurrent(currentStep);
    }, [currentStep])

    return (
        <div>
            <Steps className='steps-process' current={current} onChange={onChange}>
                <Step
                    title={lang.staff_title}
                    status={
                        (currentStep > 0 ? 'finish' : currentStep === 0 ? 'process' : 'wait')
                    }
                    disabled={currentStep < 0}
                />
                <Step
                    title={lang.service_title}
                    status={
                        (currentStep > 1 ? 'finish' : currentStep === 1 ? 'process' : 'wait')
                    }
                    disabled={currentStep < 1}
                />
                <Step
                    title={lang.check_choosed}
                    status={
                        (currentStep > 2 ? 'finish' : currentStep === 2 ? 'process' : 'wait')
                    }
                    disabled={currentStep < 2}
                />
            </Steps>
            <div className="steps-content">{stepContent[current].content}</div>
        </div>
    )
};
export default TabsStaff;
