import { configureStore } from '@reduxjs/toolkit'

import commonReducer from './modules/Common/slice'
import loginPhoneReducer from './modules/LoginPhone/slice'
import checkinReducer from './modules/Checkin/slice'

export default configureStore({
  reducer: {
    common: commonReducer,
    loginPhone: loginPhoneReducer,
    checkin: checkinReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});
