import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Routes from './routes';
import NotFound from './themes/Antd/NotFound';
import Footer from './themes/Antd/Footer/Footer';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { ROUTE_LOGIN_PHONE } from './modules/LoginPhone/constants/routes';
import { useDispatch } from 'react-redux';
import { checkinSetting } from './modules/Checkin/thunk';

const { Content } = Layout;

const Main = () => {
  const role = { getRoleByUser: [] }
  const dispatch = useDispatch()
  const userInfo = {}
  useEffect(() => {
    dispatch(checkinSetting({}))
  }, [])

  const showApp = (routes) => {
    let result = null;
    // const menus = [];
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        const permis = route.permission && route.permission !== 'undefined' ? route.permission : ''
        const permission = permis ? role.getRoleByUser.some(i => permis.indexOf(i.permission_code) >= 0) : ''
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={
              permission || userInfo.is_main === 1 || route.permission === 'ALL' ? route.main : null
            }
          />
        );
      });
    }
    return (
      <Switch>
        <Redirect exact from="/" to={ROUTE_LOGIN_PHONE} />
        {result}
        <Route component={NotFound} />
      </Switch>
    );
  };
  return (
    <Router>
      {userInfo &&
        <Layout>
          <Layout className="site-layout">
            <Content
            >
              {showApp(Routes)}
            </Content>
            <Footer />
          </Layout>
        </Layout>
      }
    </Router>
  );
};

export default Main;
