/* eslint-disable react/display-name */
import React from 'react';
import lang from './language/language';

// loginPhone
import * as LoginPhoneRoute from './modules/LoginPhone/constants/routes'
import LoginPhonePage from './modules/LoginPhone/pages/LoginPhonePage';
// regis
import * as RegisterRoute from './modules/Register/constants/routes'
import RegisterPage from './modules/Register/pages/RegisterPage';
// checkin
import * as CheckinRoute from './modules/Checkin/constants/routes'
import CheckinPage from './modules/Checkin/pages/CheckinPage';

const Routes = [
  {
    path: LoginPhoneRoute.ROUTE_LOGIN_PHONE,
    exact: true,
    main: ({ match, props }) => <LoginPhonePage match={match} props={props} />,
    name: lang.login_phone,
    permission: 'ALL'
  },
  {
    path: RegisterRoute.ROUTE_REGISTER,
    exact: true,
    main: ({ match, props }) => <RegisterPage match={match} props={props} />,
    name: lang.register,
    permission: 'ALL'
  },
  {
    path: CheckinRoute.ROUTE_CHECKIN,
    exact: true,
    main: ({ match, props }) => <CheckinPage match={match} props={props} />,
    name: lang.checkin,
    permission: 'ALL'
  }
];

export default Routes;
