
const rootCommon = 'common'
export const GEN_CODE_BY_TABLE = `${rootCommon}/gen-code-by-table`;
export const COMMON_DELETE = `${rootCommon}/delete`;
export const COMMON_UPDATE_ACTIVE_STATUS = `${rootCommon}/update-active-status`;
export const GET_FILE_EXPORT_DEMO = `${rootCommon}/get-file-export-demo`;
export const ADDRESS_PROVINCE = `${rootCommon}/province`;
export const ADDRESS_WARD = `${rootCommon}/ward`;
export const ADDRESS_DISTRICT = `${rootCommon}/district`;
export const CONDITION_TYPE = `${rootCommon}/condition-type`;
export const UPLOAD_IMAGE = `${rootCommon}/upload-image`;
export const CUSTOMER_GROUP = `${rootCommon}/customer-group`;
export const USER_LIST = `${rootCommon}/user`;
export const USER_FOR_STAFF = `${rootCommon}/user-for-staff`;
export const SERVICE_GROUP = `${rootCommon}/service-group`;
export const TITLE = `${rootCommon}/title`;
export const STAFF = `${rootCommon}/staff`;
export const CUSTOMER = `${rootCommon}/customer`;
export const SERVICE = `${rootCommon}/service`;
export const APPOINTMENT = `${rootCommon}/appointment`;
export const SERVICE_TREE = `${rootCommon}/service-tree`;
export const EMAIL_SAMPLE = `${rootCommon}/email-sample`;
export const SMS_SAMPLE = `${rootCommon}/sms-sample`;
export const INVOICE = `${rootCommon}/invoice`;
export const GIFT_CARD_MONEY_LIST = `${rootCommon}/get-gift-card-money-list`;
export const AUTO_TURN = `${rootCommon}/auto-turn`;

export const USER_INFO = 'user/detail';
export const SETTING = 'setting/general-setting';

// app
const rootApp = 'app'
export const APP_LIST = rootApp;
