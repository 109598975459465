import lang from '../language/language';

export const IS_ACTIVE_ALL = 0;
export const IS_ACTIVE = 1;
export const IS_DEACTIVE = 2;
export const SAVE = 'SAVE';
export const SAVE_CLOSE = 'SAVE_CLOSE';
export const SAVE_PRINT = 'SAVE_PRINT';
export const IS_DELETE = 1;

export const GENDER = {
    MALE: 1,
    FEMALE: 2,
    OTHER: 3
};
export const CUSTOMER_TYPE = {
    PERSONAL: 1,
    COMPANY: 2
};

export const themeColor = [
    { colorClass: 'primary' },
    { colorClass: 'success' },
    { colorClass: 'info' },
    { colorClass: 'danger' },
    { colorClass: 'warning' },
    { colorClass: 'dark' },
    { colorClass: 'indigo' },
    { colorClass: 'purple' },
    { colorClass: 'gray' },
    { colorClass: 'light' },
    { colorClass: 'orange' },
    { colorClass: 'navy' },
    { colorClass: 'fuchsia' },
    { colorClass: 'maroon' },
    { colorClass: 'teal' },
    { colorClass: 'olive' }
];
export const FORMAT_DATE = 'DD/MM/YYYY';
export const WIDTH_MODAL = {
    SMALL: '60%',
    LARGE: '100%',
    MEDIUM: '80%'
};
export const WIDTH_DRAWER = {
    SMALL: 425,
    LARGE: 736,
    FULL: 1024
};

export const OPTION_SETTING_CURRENCY = [
    { value: 'VND', label: 'VND' },
    { value: 'USD', label: 'USD' }
];

export const SIZE_PAGE = {
    PAGE: 1,
    SIZE_PER_PAGE: 10,
    SIZE_PER_PAGE_GRID: 24,
    SIZE_PER_PAGE_DETAIL: 5,
    SIZE_PER_PAGE_IMAGE_GALLERY: 23
}

export const STAFF_TYPE = {
    TECHNICIAN: 1,
    NORMAL: 2
};

export const TRUE_FALSE = {
    TRUE: 1,
    FALSE: 2
};

export const COUNTRY_LIST = {
    VN: 'VN',
    US: 'US'
};

export const CART_ITEM_TYPE = {
    SERVICE: 1,
    PRODUCT: 2
}

export const INVOICE_STATUS = {
    UNPAID: 1, // chưa thanh toán
    PAID: 2, // đã thanh toán
    CANCEL: 3 // đã huỷ
};
export const INVOICE_STATUS_LABEL = [
    { label: lang.unpaid, value: 1 },
    { label: lang.paid, value: 2 },
    { label: lang.cancel, value: 3 }
]

export const PAYMENT_METHOD = [
    { label: lang.cash, value: 1 },
    { label: lang.credit, value: 2 },
    { label: lang.gift_card, value: 3 },
    { label: lang.check, value: 4 }
]

export const CODE_TYPE = {
    INVOICE: 'INV',
    SERVICE: 'SER',
    PRODUCT: 'PRO',
    VOUCHER: 'VOU',
    GIFT_CARD: 'GIF'
}

export const KEYBOARD = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 'del', 0, 'done'
]
