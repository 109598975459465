import React, { useEffect } from 'react'
import { Avatar, Card, Col, Input, List, message, Row } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'

import lang from '../../../language/language'
import noImage from '../../../assets/images/default-user.png'
import { useDispatch, useSelector } from 'react-redux'
import { setItemList, setQueryServiceList, setQueryStaffList, setStaffChoosed } from '../slice'
import { getStaffAutoTurn } from '../../Common/service'
import moment from 'moment'

const { Search } = Input;

const ShowStaffList = () => {
    const staffList = useSelector(state => state.checkin.staffList)
    const queryStaffList = useSelector(state => state.checkin.queryStaffList)
    const staffChoosed = useSelector(state => state.checkin.staffChoosed)
    const queryServiceList = useSelector(state => state.checkin.queryServiceList)
    const currentTabs = useSelector(state => state.checkin.currentTab)
    const itemList = useSelector(state => state.checkin.itemList)
    const currentStep = useSelector(state => state.checkin.currentStep)

    const dispatch = useDispatch()

    useEffect(() => {
        if (currentTabs === '1') {
            if (currentStep === 0) {
                getStaffAuto()
            }
        }
        if (currentTabs === '2') {
            if (currentStep === 1) {
                getStaffAuto()
            }
        }
    }, [staffList, currentStep, currentTabs])

    const getStaffAuto = () => {
        getStaffAutoTurn({ date: moment().format('YYYY-MM-DD') }).then(res => {
            if (res.data) {
                const findStaff = staffList.filter(x => x.staff_id === res.data.staff_id)
                if (findStaff.length > 0) {
                    dispatch(setQueryServiceList({ ...queryServiceList, staff_id: res.data.staff_id }))
                    dispatch(setStaffChoosed(res.data))
                    const newItemList = itemList.map((item) => {
                        return { ...item, ...res.data }
                    })
                    dispatch(setItemList(newItemList))
                }
            }
        })
    }

    const onClickStaff = (data) => {
        // if (staffChoosed.staff_id === data.staff_id) { // kiểm tra trạng thái chọn của nhân viên
        //     dispatch(setStaffChoosed({}))
        // } else {

        // }
        if (data.is_busy === 1) {
            message.error(lang.staff_is_busy)
        }
        dispatch(setStaffChoosed(data))
        if (currentTabs === '1') { // kiểm tra user đang dừng ở tab nào
            dispatch(setQueryServiceList({ ...queryServiceList, staff_id: data.staff_id }))
            // if (staffChoosed.staff_id === data.staff_id) {
            //     dispatch(setQueryServiceList({ ...queryServiceList, staff_id: null }))
            // } else {
            //     dispatch(setQueryServiceList({ ...queryServiceList, staff_id: data.staff_id }))
            // }
        } else {
            const newItemList = itemList.map((item) => {
                return { ...item, ...data }
            })
            dispatch(setItemList(newItemList))
        }
    }

    const onSearch = (value) => {
        dispatch(setQueryStaffList({ ...queryStaffList, search: value }))
    }

    return (
        <Row gutter={24}>
            <Col md={24}>
                <Search
                    size='large'
                    className='i-search pb-5'
                    placeholder={lang.find_staff}
                    enterButton
                    onSearch={onSearch}
                />
            </Col>
            <List className='list-scroll pb-15'>
                <Row gutter={24}>
                    {staffList.map((item, index) => {
                        return <Col md={6} key={index} className='mt-15 text-center' >
                            <Card className='staff_info cursor-pointer' onClick={() => onClickStaff(item)}>
                                {parseInt(item.staff_id) === parseInt(staffChoosed.staff_id) && <div className='choose-staff'>
                                    <div><CheckCircleFilled className='btn-choose' /></div>
                                </div>}
                                <Avatar size={80} src={item.staff_avatar || noImage} />
                                <div className='f-16 font-weight-bold mt-5'>{item.staff_fullname}</div>
                                <div className='f-12'>{item.staff_code}</div>
                            </Card>
                        </Col>
                    })
                    }
                </Row>
            </List>
        </Row>
    )
}

export default ShowStaffList;
