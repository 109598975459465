const en = {
    user_name: 'Username',
    not_format: 'Incorrect format',
    login_main_top: 'Welcome to Hexcore!',
    login_main_bottom: 'Please login to experience the best service!',
    user_code_required: 'Please enter your User\'s code',
    user_name_required: 'Please enter your Username',
    user_password_required: 'Please enter your password',
    user_fullname_required: 'Please enter your full name',
    save_and_close: 'Save and exit',
    save: 'Save',
    password: 'Password',
    login: 'Login',
    forgot_password: 'Forgot password',
    login_not_correct: 'Your login information was incorrect',
    not_found_page: 'Page not found',
    not_found: 'Not found',
    login_phone: 'Please enter your phone number',
    value_is_required: 'Please enter a value',
    please_input_phone_number: 'Please enter your phone number',
    info_will_not_shared: 'Your information will not be shared',
    input_phone_number: 'Please enter your phone number',
    register: 'Register',
    go_back: 'Home',
    customer_phone_number: 'Customer\'s phone number',
    customer_fullname: 'Customer\'s full name',
    customer_birthday: 'Customer\'s date of birth',
    customer: 'Customers',
    checkin: 'Checkin',
    staff_title: 'Staff title',
    service_title: 'Service title',
    choose_by_staff: 'Please select a staff',
    choose_by_service: 'Please select a service',
    find_staff: 'Search for staff\'s code, name...',
    find_service: 'Search for service code, name...',
    input_wrong: 'Please enter a valid data',
    pls_check: 'Please check the Terms and Conditions',
    check_choosed: 'Confirm',
    choosed: 'Service',
    next_step: 'Next',
    price_service: 'Price',
    staff_choosed: 'Staff',
    pls_choose_service: 'Please select a service',
    policy: 'By checking the box and clicking NEXT, you give HexCheckin, as well as HexCore, express written consent to contact you at the number entered for reminder or promotional purposes. Consent is not required to make a purchase. You also  agree to the ',
    terms_and_conditions_title: 'Terms and Conditions',
    privacy_policy_title: 'Privacy Policy',
    salon_policies_title: 'Salon Policies',
    terms_and_conditions: 'This is Terms and Conditions',
    privacy_policy: 'This is Privacy Policy',
    salon_policies: 'This is Salon Policies',
    user_not_found: 'No user found!',
    exist_customer_code: 'This customer\'s code existed!',
    exist_customer_email: 'This email existed!',
    exist_customer_phone_number: 'This phone number existed!',
    welcome: 'Welcome',
    greeting_checkin: 'Please choose your services or your preferred staff, but if you walk in, you might have to wait for your technician or we woll arrange someone else for you.',
    loyalty_point: 'Loyalty point',
    price_discount: 'Discount',
    invoice_success: 'Appointment confirmed successfully!',
    invoice_error: 'Appointment error!',
    login_exist: 'This account has been logged in from another device',
    invoice_code: 'Invoice Code',
    service_ticket: 'Service Ticket',
    invoice_item_name: 'Service / Product',
    print_ticket_note: 'Please bring this ticket to the checkout counter after using the service',
    create_date: 'Date created',
    checkin_success: 'Check-in successfully!',
    staff_is_busy: 'This staff member is currently busy, you may need to wait for your turn.',
    pls_choose_staff: 'Please select a staff',
    phone_number: 'Phone number'
};

export default en;
