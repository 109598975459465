
import CallApi from '../../utils/CallAPI';
import {
  GEN_CODE_BY_TABLE,
  COMMON_DELETE,
  COMMON_UPDATE_ACTIVE_STATUS,
  GET_FILE_EXPORT_DEMO,
  APP_LIST,
  ADDRESS_PROVINCE,
  ADDRESS_WARD,
  ADDRESS_DISTRICT,
  CONDITION_TYPE,
  UPLOAD_IMAGE,
  CUSTOMER_GROUP,
  USER_LIST,
  SERVICE_GROUP,
  TITLE,
  STAFF,
  CUSTOMER,
  SERVICE,
  APPOINTMENT,
  SERVICE_TREE,
  EMAIL_SAMPLE,
  SMS_SAMPLE,
  INVOICE,
  GIFT_CARD_MONEY_LIST,
  USER_INFO,
  SETTING,
  AUTO_TURN
} from './constants/endpoint';

export const genCodeByTable = (data) => {
  return CallApi(GEN_CODE_BY_TABLE, 'POST', data)
};

export const commonDelete = (data) => {
  // const data = {
  //   list_id: [userId],
  //   field_name: 'user_id',
  //   table: 'user',
  //   update_user: userInfo.user_id
  // };
  return CallApi(COMMON_DELETE, 'DELETE', data)
};

export const updateActiveStatus = (data) => {
  // const data = {
  //   list_id: [userId],
  //   field_name: 'user_id',
  //   table: 'user',
  //   update_user: userInfo.user_id
  // };
  return CallApi(COMMON_UPDATE_ACTIVE_STATUS, 'PUT', data)
};

export const getFileExportDemo = (data) => {
  return CallApi(GET_FILE_EXPORT_DEMO, 'GET', data)
};

export const appList = (data) => {
  return CallApi(APP_LIST, 'GET', data).catch(error => {
    const { data } = error.response;
    return data
  });
};

export const getProvince = (data) => {
  return CallApi(ADDRESS_PROVINCE, 'GET', data)
};

export const getWard = (data) => {
  return CallApi(ADDRESS_WARD, 'GET', data)
};

export const getDistrict = (data) => {
  return CallApi(ADDRESS_DISTRICT, 'GET', data)
};

export const toggleColumn = (data) => {
  return CallApi('toggle-column', 'PUT', data)
};

export const getConditionType = (data) => {
  return CallApi(CONDITION_TYPE, 'GET', data)
};

export const uploadImage = (data) => {
  return CallApi(UPLOAD_IMAGE, 'POST', data, 'multipart/form-data')
};

export const getCustomerGroup = (data) => {
  return CallApi(CUSTOMER_GROUP, 'GET', data)
};

export const getUserList = (data) => {
  return CallApi(USER_LIST, 'GET', data)
};

export const getServiceGroup = (data) => {
  return CallApi(SERVICE_GROUP, 'GET', data)
};

export const getTitle = (data) => {
  return CallApi(TITLE, 'GET', data)
};

export const getStaff = (data) => {
  return CallApi(STAFF, 'GET', data)
};
export const getCustomer = (data) => {
  return CallApi(CUSTOMER, 'GET', data)
};

export const getService = (data) => {
  return CallApi(SERVICE, 'GET', data)
};

export const getAppointment = (data) => {
  return CallApi(APPOINTMENT, 'GET', data)
};
export const getServiceTree = (data) => {
  return CallApi(SERVICE_TREE, 'GET', data)
};

export const getEmailSample = (data) => {
  return CallApi(EMAIL_SAMPLE, 'GET', data)
};

export const getSMSSample = (data) => {
  return CallApi(SMS_SAMPLE, 'GET', data)
};

export const getInvoice = (data) => {
  return CallApi(INVOICE, 'GET', data)
};

export const getGiftCardMoneyList = (data) => {
  return CallApi(GIFT_CARD_MONEY_LIST, 'GET', data)
};

export const getUserInfo = (data) => {
  return CallApi(USER_INFO, 'GET', data)
};

export const getSetting = (data) => {
  return CallApi(SETTING, 'GET', data)
};
export const getStaffAutoTurn = (data) => {
  return CallApi(AUTO_TURN, 'GET', data)
};
