import React, { useEffect } from 'react'
import moment from 'moment';
import { Button, Form, Input, DatePicker, Card, Row, Col, Space, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import lang from '../../../language/language';
import { ROUTE_LOGIN_PHONE } from '../../LoginPhone/constants/routes'
import { ROUTE_CHECKIN } from '../../Checkin/constants/routes'
import { formatPhone } from '../../Common/components/FormatData';
import { register } from '../service'
import { setLoading } from '../../Common/slice';
import { RandomCode } from '../../Common/components/RandomCode';
import { setCustomerInfo } from '../../Checkin/slice'

const Register = () => {
  const history = useHistory()
  const loginPhoneNew = useSelector(state => state.loginPhone.loginPhoneNew)
  const userInfo = useSelector(state => state.common.userInfo)
  const [form] = Form.useForm()

  useEffect(() => {
    if (!loginPhoneNew) { // kiểm tra state loginPhoneNew có dữ liệu trong redux không
      history.push(ROUTE_LOGIN_PHONE)
    }
  }, [loginPhoneNew])

  const registerNew = (values) => {
    dispatch(setLoading(true))

    const reqData = {
      customer_fullname: values.customer_fullname,
      customer_phone_number: loginPhoneNew,
      customer_email: values.customer_email,
      customer_birthday: values.customer_birthday ? moment(values.customer_birthday).format('YYYY-MM-DD') : '',
      create_user: userInfo.user_id,
      branch_id: 1,
      customer_code: RandomCode()
    }
    register(reqData).then(res => {
      if (res) {
        if (res.data.status === false) { // kiểm tra lý do lỗi đăng ký
          if (res.data.customer_code) message.error(lang.exist_customer_code);
          else if (res.data.customer_email) message.error(lang.exist_customer_email);
          else if (res.data.customer_phone_number) message.error(lang.exist_customer_phone_number);
        } else {
          dispatch(
            setCustomerInfo(res.data)
          )
          history.push(ROUTE_CHECKIN)
        }
      }
    }).finally(x => {
      dispatch(setLoading(false))
    }
    )
  }

  const onSubmit = () => {
    form.submit()
  }

  const dispatch = useDispatch()

  const onGoBack = () => {
    history.push(ROUTE_LOGIN_PHONE)
  }

  return (
    <Card className='pb-30 pt-15'>
      <div className='title-all'>{lang.register}</div>
      <Form
        form={form}
        layout="vertical"
        name="regisForm"
        onFinish={registerNew}
      >
        <Row gutter={24}>
          <Col md={12}>
            <Form.Item
              name="customer_phone_number"
              label={lang.customer_phone_number}
              initialValue={formatPhone(loginPhoneNew)}
            >
              <Input disabled size='large' />
            </Form.Item></Col>
          <Col md={12}>
            <Form.Item
              name="customer_fullname"
              label={lang.customer_fullname}
              rules={[
                {
                  required: true,
                  message: lang.value_is_required
                }
              ]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name="customer_email"
              label="Email"
              rules={[
                {
                  type: 'email',
                  message: lang.input_wrong
                }
              ]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name="customer_birthday"
              label={lang.customer_birthday}
            >
              <DatePicker size='large' style={{ width: '100%' }} format='DD-MM-YYYY' />
            </Form.Item>
          </Col>
          <Col md={24} className='mt-30 text-center'>
            <Space size={'large'}>
              <Button type="primary" size='large' onClick={onGoBack}>
                {lang.go_back}
              </Button>
              <Button onClick={onSubmit} type="primary" size='large'>
                {lang.register}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default Register;
