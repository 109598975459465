import { createAsyncThunk } from '@reduxjs/toolkit';
import * as commonService from './service';

export const getFileExportDemo = createAsyncThunk(
    'common/getFileExportDemo',
    async (data, thunkAPI) => {
        const response = await commonService.getFileExportDemo(data);
        return response.data;
    }
);

export const appList = createAsyncThunk(
    'common/appList',
    async (data, thunkAPI) => {
        const response = await commonService.appList(data);
        return response.data;
    }
);
export const customerPhoneList = createAsyncThunk(
    'common/customerPhoneList',
    async (data, thunkAPI) => {
        const response = await commonService.getCustomer(data);
        return response.data.map((item) => item.customer_phone_number).filter(i => i !== '');
    }
);
export const getInvoiceList = createAsyncThunk(
    'common/invoiceList',
    async (data, thunkAPI) => {
        const response = await commonService.getInvoice(data);
        return response.data;
    }
);
