import moment from 'moment-timezone';
import * as c from '../../../constants/common';
import * as config from '../../../constants/config';
import lang from '../../../language/language';

export const formatDate = (date, isShowHour = false, setting = '') => {
    const generalSetting = setting
    if (date) {
        return moment(date).tz(config.TIME_ZONE).format(generalSetting.general_setting_date_format + (isShowHour ? ' hh:mm A' : ''));
    } else {
        return ''
    }
}
export const formatPhone = (phone) => {
    if (phone) {
        return '(' + phone.slice(0, 3) + ') ' + phone.slice(3, 6) + '-' + phone.slice(6, 10)
    } else {
        return ''
    }
}
export const formatCurrency = (num, setting = '') => {
    const generalSetting = setting;
    let money = '';
    if (generalSetting.general_setting_currency && generalSetting.general_setting_currency === c.OPTION_SETTING_CURRENCY[0].value) {
        money = 'vi-VI';
    } else if (generalSetting.general_setting_currency && generalSetting.general_setting_currency === c.OPTION_SETTING_CURRENCY[1].value) {
        money = 'en-EN';
    }
    if (generalSetting.general_setting_currency) {
        const formatter = new Intl.NumberFormat(money, {
            style: 'currency',
            currency: generalSetting.general_setting_currency,
            minimumFractionDigits: 0
        })
        if (num) {
            const number = formatter.format(num);
            return number;
        } else {
            const number = formatter.format(0);
            return number
        }
    }
}
export const formatIsActive = (status) => {
    if (status === c.IS_ACTIVE) {
        return lang.active
    } else {
        return lang.de_active
    }
}

export const formatBranchMain = (num) => {
    if (num === 1) {
        return lang.is_main
    } else {
        return lang.not_main
    }
}

export const formatPrice = (string = '') => {
    if (string) {
        return parseInt(string.replace('$', '')
            .replace(',', '')
            .replace(',', '')
            .replace(',', '')
            .replace(',', '')
            .replace(',', '')
            .replace('VND', '')
            .replace('.', '')
            .replace('.', '')
            .replace('.', '')
            .replace('.', '')
            .replace('.', '')
        )
    } else {
        return 0
    }
}
