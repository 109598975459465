import { createAsyncThunk } from '@reduxjs/toolkit';
import * as checkinService from './service'
import { getServiceTree } from '../Common/service'

export const staffList = createAsyncThunk(
  'checkin/staffList',
  async (data, thunkAPI) => {
    const response = await checkinService.getStaff(data);
    return response.data;
  }
);

export const serviceList = createAsyncThunk(
  'checkin/serviceList',
  async (data, thunkAPI) => {
    const response = await getServiceTree(data);
    return response.data;
  }
);

export const checkinSetting = createAsyncThunk(
  'checkin/checkinSetting',
  async (data, thunkAPI) => {
    const response = await checkinService.checkinSetting(data);
    return response.data;
  }
);
