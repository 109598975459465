import { createSlice } from '@reduxjs/toolkit';
import * as commonThunk from './thunk';

export const commonSlice = createSlice({
    name: 'common',
    initialState: {
        title: '',
        loading: false,
        loadingTable: false,
        setting: {},
        userInfo: {}
    },
    reducers: {
        updateTitle: (state, action) => {
            state.title = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setLoadingTable: (state, action) => {
            state.loadingTable = action.payload;
        },
        setSetting: (state, action) => {
            state.setting = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        }
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [commonThunk.getFileExportDemo.fulfilled]: (state, action) => {
            state.getFileExportDemo = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    updateTitle,
    setLoading,
    setLoadingTable,
    setSetting,
    setUserInfo
} = commonSlice.actions;

export default commonSlice.reducer;
