import { createSlice } from '@reduxjs/toolkit';
import * as checkinThunk from './thunk';

export const CheckinSlice = createSlice({
  name: 'checkin',
  initialState: {
    currentStep: 0,
    currentTab: '1',
    customerInfo: {},
    itemList: [],
    staffList: [],
    serviceList: [],
    checkinSetting: {},
    queryStaffList: {
      listServiceId: [],
      listBranchId: [],
      search: ''
    },
    queryServiceList: {
      staff_id: null,
      search: ''
    },
    staffChoosed: {}
  },
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setItemList: (state, action) => {
      state.itemList = action.payload;
    },
    setQueryStaffList: (state, action) => {
      state.queryStaffList = action.payload;
    },
    setQueryServiceList: (state, action) => {
      state.queryServiceList = action.payload;
    },
    setStaffChoosed: (state, action) => {
      state.staffChoosed = action.payload;
    }

  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [checkinThunk.staffList.fulfilled]: (state, action) => {
      state.staffList = action.payload;
    },
    [checkinThunk.serviceList.fulfilled]: (state, action) => {
      state.serviceList = action.payload;
    },
    [checkinThunk.checkinSetting.fulfilled]: (state, action) => {
      state.checkinSetting = action.payload;
    }
  }
});

export const {
  setCurrentStep,
  setCurrentTab,
  setCustomerInfo,
  setItemList,
  setQueryStaffList,
  setQueryServiceList,
  setStaffChoosed
} = CheckinSlice.actions;

export default CheckinSlice.reducer;
