import CallApi from '../../utils/CallAPI';
import {
  SLIDER,
  LOGIN_PHONE
} from './constants/endpoint';

export const getSlider = (data) => {
  return CallApi(SLIDER, 'GET', data)
};

export const checkLoginPhone = (data) => {
  return CallApi(LOGIN_PHONE, 'GET', data)
};
