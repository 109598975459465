import randomstring from 'randomstring';

export const RandomCode = (codeType = '') => {
    let code = ''
    if (codeType) {
        code = codeType + randomstring.generate(7);
    } else {
        code = randomstring.generate(10);
    }
    return code.toLocaleUpperCase()
}
